body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

:root {
	/* Base font size */
	font-size: 10px;

	/* Heading height variable*/
	--heading-height: 13em;
}

body {
	font-family: "Roboto", Arial, sans-serif;
	min-height: 100vh;
	background-color: #101010;
}

header {
	position: fixed;
	width: 100%;
	height: var(--heading-height);
}

/* Create angled background with 'before' pseudo-element */
header::before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	bottom: 3em;
	width: 100%;
	height: calc(var(--heading-height) + 10em);
	z-index: -1;
	transform: skewY(-3.5deg);
	background: 
		linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)),
		url(ladder.png) no-repeat center,
		linear-gradient(#4e4376, #2b5876);
	background-size: cover;
	border-bottom: .2em solid #fff;
}

h1 {
	font-size: calc(1.8em + 1.6vw);
	font-weight: 700;
	letter-spacing: .01em;
	padding: 0.1rem 0 0 3.5rem;
	text-shadow: .022em .022em .022em #111;
	color: #fff;
}

main {
	padding: calc(var(--heading-height) + 1.5vw) 4em 0;
}

p {
	font-size: calc(1.2em + .25vw);
	font-weight: 400;
	line-height: 2;
	margin-bottom: 1.5em;
	color: #fcfcfc;
}

